$card-count: 7;

.projects {

	&__cards-list {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 2.4rem;

		@media ($min-sm) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media ($min-lg) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.project-card {
		display: flex;
		flex-direction: column;
		background-color: $white;
		padding: 2.4rem;
		gap: 1.6rem;

		@media ($min-lg) {
			opacity: 0;
			transform: translateY(3rem);
			animation: projectFadeInUp 0.5s forwards;

			@for $i from 1 through $card-count {
				&:nth-of-type(#{$i}) {
					animation-delay: calc(0.25s * (#{$i} - 1));
				}
			}
		}

		&:hover {
			.project-card__icon img {
				transform: scale(1.2);
			}
		}

		&__media {
			position: relative;
			padding-block-start: 56.25%;
			width: 100%;
			background-color: $text-color;

		}

		&__video {
			border: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&__image {
			border: solid 2px $bg-color;
			height: 200px;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				max-width: 100%;
			}
		}

		&__body {
			display: flex;
			flex-direction: column;
			gap: 1.6rem;
		}

		&__name {
			em {
				text-transform: lowercase;
			}
		}

		&__sheet,
		&__awards {
			list-style: none;
			display: flex;
			flex-direction: column;
			gap: 1.6rem;
		}

		&__awards {
			li {
				font-size: 1.4rem;
				position: relative;
				padding-inline-start: 4rem;

				&:before {
					content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANrSURBVHgB7ZZpiI1hFMf/dmM3WbIvEdn3tRQlQlIaSbaILyhZyp412bdQyE4ha8IXGesH+wcRMxga6zDMZBnGGP/j/Me9M92Z7p3rAzWnfr3ve55zzrOd5zwvUCT/sDQkrRG9tCWNEaF0JGlkH6KX/SSddInE6TLJ1jNauaJYV0M1FtezWJDO3rvr/Q2il1d6dg3Rzx9ZReYHNbyHj3oIopfBivUuqNNFZE2wUZKMRunbGufJYThpjsilBRkJX+U5ZKX0Y9XXs2Dj51Le13dtUpKclH4yIpcp8j1LSpG60j8INYCtUmbBj008WSBdtmYSqYwO8l+omO3IT+k25xj2IrXIbfhK2MhnIrAtKaQiIhfzeasYSWSGYtvMb6rP3mb4iSTCs9RGuI4MhCeNOXZG4aUTeQKfxACyHl7ceij2ZzN6oVGmkupklxxtK5qRPaQEIhfzOQBP4FaKabFrkg/qM9kydLscqpKJJIOUIy/JefheFqYktyEj4HufopgWewKpIpttNoAlZAs8AeuTjyQWnjh1ZJiNyCVLz5qKFavY9cgPspEss3Pel7Qn10kyPA/sO440JQnwZaykAOGIrabdJXasbRstx46QW/Bkt4n21DcmaYbfyVyN8BK5C98Gu5hsL08gfDlFDpEOmtQd+L1SV6uRqT6t79/KdATO7FCN0kpoZTJV+jSEL2nymQY/joPgRy8uqB+zydli9Cev1XAMnigr1JYgfTLCl2T5JOrb7prx5Lj0dvL65nWqooHY3sTI2VYgQ07LyRjp8pOq6mipfDIU12KVJd3UR2xeRyu1lrXfyAV4UZpF1pJr5DD8HJtNQT8pB+GltpPe7R9gA7yyWvGxPMiUTa7ybrNKQmB/bOSdFSBONovUZoMMVZhKqM1sFks3TJ12QWAljafwU5VLGpHT5As8KS1Dm5BHpBrZIWc7wzEhBhCDQHabbQ14/tj/oN2mqYp7FH4M8xUrTnYd231eHp6QdqXOVvD4AnwvyMbuf6ui4zRTqysNEKZMQ2CpvsIvJ0vAi/Dj2aYAX6v5N+B5Y4m4CbmXfnpeh+IhguxG4MfEMneq3uPh/3cPkb88hlfLc6Q0fOnLqO0e2YkwpQL8/t5LVpOW0i8kZ+B/S3nFdLbs8/RtJXi5OrVJlMNfkn4FtPVBkfxv8gtbH+4E2paKvAAAAABJRU5ErkJggg==");
					position: absolute;
					width: 32px;
					left: 0;
					top: 0;
				}
			}

			small {
				text-transform: uppercase;
				font-size: 1.2rem;
				display: block;
			}
		}

		&__logline {
			line-height: 150%;
		}
	}
}

@keyframes projectFadeInUp {
	from {
		opacity: 0;
		transform: translateY(3rem);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}