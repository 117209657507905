.header {
	position: fixed;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 10;
	top: 3.2rem;
	
	@media ($min-lg) {
		top: 6.4rem;
	}

	&__container {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-block: 2.4rem;
		transition: position .3s ease, padding .3s ease;

		@media ($min-xl) {
			padding-block: 3.2rem;
		}

		&:after {
			content: "";
			position: absolute;
			width: calc(100% - 4.8rem);
			bottom: 0;
			left: 2.4rem;
			border-block-end: solid 1px rgba($text-color, .1);
		}
	}

	&--sticky {
		background-color: rgba($bg-color, .75);
		backdrop-filter: blur(7px);
		border-block-end: solid 1px rgba($text-color, .05);

		.header__container {
			padding-block: .8rem;
			
			&:after {
				content: none;
			}
		}
	}

	&__logo {
		font-size: 3.2rem;
		line-height: 125%;
		font-family: $title-font;
		text-decoration: none;
		font-weight: 400;

		@media screen and ($max-sm) {
			font-size: 2.4rem;
			line-height: 1;
		}
	}
}