.menu {
	display: flex;
	align-items: center;
	transition: all .3s ease;
	
	@media ($max-lg) {
		position: fixed;
		height: calc(100vh - 6.4rem);
		inset: 0;
		width: 100%;
		padding-inline: 2.4rem;
		background-color: rgba($bg-color, .75);
		z-index: 9;
		backdrop-filter: blur(3px);
		visibility: hidden;
		opacity: 0;
	}

	@media ($min-lg) {
		margin-inline-end: -.8rem;
	}

	&__list {
		display: flex;
		flex-direction: column;
		list-style: none;
		align-items: flex-start;

		@media ($min-lg) {
			align-items: center;
			flex-direction: row;
		}
	}

	&__item {
		position: relative;
		margin-block: .8rem;
		display: inline-flex;

		@media ($min-lg) {
			margin: 0 .8rem;
			display: initial;
		}

		&:not(:first-child):before {
			@media ($min-lg) {
				content: "/";
				margin-inline-end: 1.6rem;
			}
		}
	}

	&__link {
		font-size: 2rem;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: -.03rem;
		position: relative;
		text-decoration: none;
		transition: all .3s ease;
		padding: .8rem;
		
		@media ($min-lg) {
			font-size: 1.6rem;
			font-weight: 900;
		}

		&:hover,
		&:focus-visible,
		&:focus {
			background-color: $text-color;

			.menu__text {
				border: none;
				color: $bg-color;
			}
		}

		&--active {
			background-color: $text-color;
			padding-inline-end: 1.2rem;

			@media ($min-lg) {
				padding-inline-end: .8rem;
			}

			&:before {
				content: "";
				position: absolute;
				height: 100%;
				width: 2.5rem;
				left: -2.5rem;
				bottom: 0;
				background-color: $text-color;
				
				@media ($min-lg) {
					width: 100%;
					height: 80px;
					left: 0;
				}
			}

			.menu__text {
				color: $bg-color;
				border-bottom: none;
			}
		}
	}

	&__btn {
		@extend .menu__link;

		&:hover,
		&:focus-visible,
		&:focus,
		&--active {
			background-color: $text-color;
			
			.menu__text {
				border: none;
				color: $bg-color;
			}
		}
	}

	&__text {
		position: relative;
		border-bottom: solid 1px $text-color;
		z-index: 1;
		color: $text-color;
	}

	&--open {
		opacity: 1;
		visibility: visible;

		&:before {
			content: "Menu";
			position: fixed;
			font-family: $title-font;
			line-height: 1;
			left: 3.2rem;
			top: 2.4rem;
			left: 2.4rem;
			font-size: 3.6rem;
			line-height: 1.5;
			font-size: 2.4rem;

			@media ($min-lg) {
				left: 3.2rem;
				font-size: 3.6rem;
				display: none;
			}
		}
	}
}

#menuBtn {
	width: 24px;
	height: 20px;
	transform: rotate(0deg);
	transition: .4s ease-in-out;
	cursor: pointer;
	display: none;
	position: relative;
	z-index: 10;

	@media screen and ($max-lg) {
		display: block;
	}

	span {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		background: $text-color;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;

		&:nth-child(1) {
			top: 0;
		}

		&:nth-child(2),
		&:nth-child(3) {
			top: 8px;
		}

		&:nth-child(4) {
			top: 16px;
		}
	}

	&.open {
		span {
			&:nth-child(1) {
				top: 8px;
				width: 0;
				left: 50%;
			}

			&:nth-child(2) {
				transform: rotate(45deg);
			}

			&:nth-child(3) {
				transform: rotate(-45deg);
			}

			&:nth-child(4) {
				top: 8px;
				width: 0;
				left: 50%;
			}
		}
	}
}