.home {
	display: flex;
	align-items: center;
	justify-content: center;

	h1:after {
		content: "|";
		margin-inline-start: .6rem;
		animation: blinker 1s linear infinite;
	}

	.container {
		align-items: center;
	}

	.menu {
		top: 3.2rem;
	}

	#menuBtn {
		position: fixed;
		top: 5.4rem;
		right: 3.2rem;
		
		@media ($min-lg) {
			display: none;
		}
	}
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}