.about {
	background-attachment: fixed;
	background-size: cover;
	background-image: url(../images/bg-about_mobile.webp);
	background-position: center right;

	@media ($min-lg) {
		background-image: url(../images/bg-about_desktop.webp);
	}

	.text {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 700px;
		
		@media screen and ($max-md) {
			width: 100%;
		}

		p {
			@media ($min-lg) {
				animation: fadeIn  1s ease-in-out;
			}
		}
	}
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}