//breakpoints
$break-xs: 576px;
$break-sm: 768px;
$break-md: 992px;
$break-lg: 1200px;
$break-xl: 1400px;

//media queries
$max-xs: 'max-width:'$break-xs - 1px;
$max-sm: 'max-width:'$break-sm - 1px;
$max-md: 'max-width:'$break-md - 1px;
$max-lg: 'max-width:'$break-lg - 1px;
$max-xl: 'max-width:'$break-xl - 1px;

$min-xs: 'min-width:'$break-xs;
$min-sm: 'min-width:'$break-sm;
$min-md: 'min-width:'$break-md;
$min-lg: 'min-width:'$break-lg;
$min-xl: 'min-width:'$break-xl;

//fonts
$title-font: 'Playfair Display', serif;
$paragraph-font: 'Lato', sans-serif;

//colors
$white: #fff;
$bg-color: #D9D9D9;
$bg-support-color: darken($bg-color, 10%);
$text-color: #000;