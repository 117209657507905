@use "sass:math";

.loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $text-color;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
	visibility: hidden;
	opacity: 0;
	transition: .3s ease;

	&.show {
		visibility: visible;
		opacity: 1;
	}
}

.loading-text {
	height: 100px;
	line-height: 100px;

	span {
		display: inline-block;
		margin: 0 5px;
		color: $bg-color;
		font-family: $title-font;
		font-size: 3rem;

		@media screen and ($max-sm) {
			font-size: 1.5rem;
		}

		@for $i from 0 through 11 {
			&:nth-child(#{$i + 1}) {
				filter: blur(0px);
				animation: blur-text 1.5s math.div($i, 5)+s infinite linear alternate;
			}
		}
	}
}

@keyframes blur-text {
	0% {
		filter: blur(0px);
	}

	100% {
		filter: blur(3px);
	}
}