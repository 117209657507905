*,
*:before,
*:after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	outline: none;
}

html {
	font-size: 62.5%;
	font-family: $paragraph-font;
	overflow-x: hidden;
	scroll-behavior: smooth;
	width: 100%;
}

body {
	display: flex;
	flex-direction: column;
	color: $text-color;
	overflow-x: hidden;
	width: 100%;
	min-height: 100vh;
	position: relative;
	font-size: 16px;
	background-color: $bg-color;
	padding-block: 3.2rem;

	@media ($min-lg) {
		padding-block: 6.4rem;
	}

	&:after {
		content: "";
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border-block: solid 3.2rem $text-color;
		pointer-events: none;

		@media ($min-sm) {
			border-width: 3.2rem;
		}

		@media ($min-lg) {
			border-width: 6.4rem;
		}
	}
}

main {
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;

	&:not(.home) {
		padding-block: 9.6rem 2.4rem;

		@media ($min-sm) {
			padding-block: 12.8rem 3.2rem;
		}

		@media ($min-xl) {
			padding-block: 14.4rem 6.4rem;
		}
	}
}

.container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-inline: auto;
	padding-inline: 2.4rem;

	@media ($min-xs) {
		max-width: 540px
	}

	@media ($min-sm) {
		max-width: 720px;
	}

	@media ($min-md) {
		max-width: 960px;
	}

	@media ($min-lg) {
		max-width: 1140px;
	}

	@media ($min-xl) {
		max-width: 1320px;
	}
}