h1,
h2 {
	font-family: $title-font;
	font-weight: 400;
	line-height: 125%;

	@media screen and ($max-sm) {
		line-height: 125%;
	}
}

h1 {
	font-size: 4.8rem;
	margin-block-end: 3.2rem;
}

h2 {
	font-size: 2rem;
	text-transform: uppercase;
	font-weight: 900;
	letter-spacing: 1px;
}

p {
	font-size: 1.6rem;
	line-height: 200%;
	color: $text-color;
	font-weight: 400;

	&+p {
		margin-block-start: 1.6rem;
	}
}