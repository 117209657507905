button {
	background: none;
	border: none;
	cursor: pointer;
	pointer-events: all;
	font-family: $paragraph-font;
	font-weight: 600;
}

.btn-outline {
	border: solid 2px $text-color;
	color: $text-color;
	padding: 1.6rem 6.4rem;
	border-radius: 6.4rem;
	font-size: 1.6rem;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: -1.5%;
	font-family: $paragraph-font;
	text-decoration: none;

	@media screen and ($max-sm) {
		font-size: 1.2rem;
		padding: 1.2rem 3.2rem;
	}

	&:hover {
		background-color: $text-color;
		color: $bg-color;
	}
}