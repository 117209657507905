.spec {
	padding-inline: 0;
	padding-block-end: 0;
	background-color: $bg-color;

	&__overlay {
		background-color: rgba($bg-color, .9);

		@media ($min-sm) and (min-height: 600px) {
			position: fixed;
			display: flex;
			left: 0;
			width: 100%;
			min-height: calc(100% - 6.4rem);
			display: flex;
			align-items: center;
		}

		@media ($min-sm) {
			min-height: calc(100% - 6.4rem);
			top: 3.2rem;
		}

		@media ($min-lg) {
			min-height: calc(100% - 12.8rem);
			top: 6.4rem;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 600px;
		margin-inline: auto;
		text-align: left;
		align-items: flex-start;

		@media ($min-md) and (min-height: 600px) {
			margin-block-start: 6.4rem;
			margin-inline: auto;
		}

		@media ($min-sm) {
			text-align: center;
			align-items: center;
		}

		@media ($min-md) {
			max-width: 750px;
		}

		@media ($min-lg) {
			max-width: 1100px;
			animation: fadeIn 1s ease-in-out;
		}

		@media ($min-sm) and (min-height: 600px) {}

		h1 {
			@media ($min-lg) {
				display: initial;
			}

			@media (max-height: 500px) {
				display: none;
			}
		}

		p {
			@media ($max-sm) or (max-height: 500px) {
				line-height: 150%;
				font-size: 1.4rem;
			}
		}
	}

	&__scroll-indicator {
		border-style: solid;
		border-width: .20em .20em 0 0;
		height: 1.25em;
		transform: rotate(135deg);
		width: 1.25em;
		animation: bounce 2s ease infinite;
		margin-block-start: 3rem;
		display: none;

		@media ($min-sm) {
			margin-inline: auto;
		}

		@media ($min-sm) and (min-height: 600px) {
			display: initial;
		}
	}

	&__pdf {
		width: 100%;
		height: 100%;
		margin-block-start: -10%;
		display: none;

		@media ($min-sm) and (min-height: 600px) {
			display: initial;
		}
	}

	&__page {
		width: 100%;
		height: auto;
		margin-bottom: 10px;
		background: white;
	}

	&__download {
		text-decoration: none;
		text-transform: uppercase;
		border: solid 2px transparent;
		border-radius: 3.2rem;
		background-color: $text-color;
		color: $white;
		font-size: 1.2rem;
		padding: 1.2rem 2.4rem;
		margin-block: 2.4rem;

		@media ($min-md) {
			font-size: 1.4rem;
			padding: 1.6rem 3.2rem;
			opacity: 0;
			visibility: hidden;
			position: fixed;
			bottom: 4.8rem;
			right: 1.6rem;
			margin: 0;
		}

		@media ($min-lg) {
			bottom: 8rem;
		}

		&--visible {
			opacity: 1;
			visibility: visible;
		}

		&:hover,
		&:focus {
			background-color: $bg-color;
			color: $text-color;
			border-color: $text-color;
		}
	}
}

@keyframes bounce {

	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0) rotate(135deg);
	}

	40% {
		transform: translateY(-1.5rem) rotate(135deg);
	}

	60% {
		transform: translateY(-1rem) rotate(135deg);
	}
}