.contact {
	background-color: $text-color;
	position: fixed;
	bottom: -20rem;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 900px;
	padding: 3.2rem 6.4rem;
	visibility: hidden;
	opacity: 0;
	transition: .3s ease;
	z-index: -1;

	@media screen and ($max-md) {
		width: 100%;
		max-width: initial;
	}

	@media ($max-sm ){
		padding: 1.6rem 3.2rem;
	}

	&--show {
		bottom: 6.3rem;
		visibility: visible;
		opacity: 1;
		z-index: 5;

		@media ($max-xl) {
			bottom: 3.1rem;
		}

		@media ($max-sm) {
			bottom: 1.5rem;
		}
	}

	& * {
		color: $bg-color;
	}

	&__list {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;

		@media screen and ($max-md) {
			flex-direction: column;
			align-items: flex-start;
			gap: 1.6rem;
		}

		li {
			list-style: none;
			display: inline-flex;
			align-items: center;

			img {
				margin-inline-end: 1.6rem;

				@media screen and ($max-md) {
					display: none;
				}
			}

			a {
				text-decoration: none;
				font-size: 3rem;
				line-height: 3rem;
				font-weight: 500;

				@media screen and ($max-sm) {
					font-size: 2.4rem;
				}
			}

			p {
				font-size: 1.6rem;

				a {
					font-size: inherit;
					border-bottom: solid 1px;
				}

				@media screen and ($max-sm) {
					font-size: 1.4rem;
				}
			}
		}
	}

	&__close {
		position: absolute;
		top: 1.6rem;
		right: 1.6rem;

		@media screen and ($max-md) {
			top: 1.6rem;
			right: 3.2rem;
		}

		img {
			filter: invert(100%);
			width: 16px;
			height: 16px;
		}
	}
}